import React from "react"

import "./about.scss"

export default () => (
  <section id="about-us" className="dark">
    <div className="container">
      <h2>About Frost Diesel Injection</h2>
      <p>
        Frost Diesel Injection was established by Mike and Leanne Frost in 1993
        and has continued to service customers all over Australia with
        old-fashioned service, guaranteed quality of workmanship and parts, and
        competitive pricing.
      </p>
      <p>
        Mike will be the one talking with you on the phone, discussing your
        needs, servicing and reconditioning your pumps and injectors and sending
        out your work.
      </p>
      <p>
        Attention to detail, honesty of work, liaising with the customers
        throughout the job is what keeps Mike's customers coming back and
        recommending others also.
      </p>
    </div>
  </section>
)
