import * as React from "react"

import "./header.scss"

const Header = () => {
  const phone = "3274 4834"

  const [isOpen, setIsOpen] = React.useState(false)

  const onClickMenuButton = React.useCallback(() => {
    setIsOpen(x => !x)
  }, [])

  const onNavClick = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  React.useEffect(() => {
    if (isOpen) {
      document.querySelector("body").classList.add("dim")
    } else {
      document.querySelector("body").classList.remove("dim")
    }
  }, [isOpen])

  const links = [
    {
      href: "/#home",
      label: "Home",
    },
    {
      href: "/#about-us",
      label: "About Us",
    },
    {
      href: "/#our-services",
      label: "Our Services",
    },
    {
      href: "/#testimonials",
      label: "Testimonials",
    },
    {
      href: "/#contact-us",
      label: "Contact Us",
    },
  ]

  return (
    <>
      <header>
        <a href="/" className="logo"></a>
        <button className="menu" onClick={() => onClickMenuButton()}>
          Menu
        </button>
        <a href={`tel:${phone.replace(" ", "")}`} className="phone-number">
          {phone}
        </a>
        <nav className={"nav " + (isOpen ? "open" : "")}>
          <h4>Navigation</h4>
          <ul>
            {links.map(link => (
              <li key={link.href}>
                <a onClick={onNavClick} href={link.href}>
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </header>
    </>
  )
}

export default Header
