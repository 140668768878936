import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <Helmet title={defaultTitle} />
      <Header />
      <main>{children}</main>
      <footer>
        <div className="container">
          <span>
            &copy; Frost Diesel Injection 1993-{new Date().getFullYear()}
          </span>
          <br />
          <span>Photography by RP Imaging</span>
        </div>
      </footer>
    </>
  )
}

export default Layout
