import React from "react"

import "./features.scss"
import workmanship from "../images/quality-diesel-workmanship.svg"
import oldFashioned from "../images/old-fashioned-personal-service.svg"
import competitive from "../images/competitive-affordable-pricing.svg"

const features = [
  {
    image: {
      src: workmanship,
      alt: "Quality diesel workmanship",
    },
    heading: "Quality Workmanship",
    description: `We guarantee diesel injection workmanship of the highest quality and
    only use top-grade parts.`,
  },
  {
    image: {
      src: oldFashioned,
      alt: "Old fashioned, personal service",
    },
    heading: "Old-Fashioned Service",
    description: `Building personal relationships with our clients helps to ensure we
    can deliver a service perfectly suited to our clients' needs.`,
  },
  {
    image: {
      src: competitive,
      alt: "Competitive affordable pricing",
    },
    heading: "Competitive Pricing",
    description: `Our services are competitively priced so that your diesel repair
    comes at the smallest possible cost to you.`,
  },
]

export default () => (
  <section className="features">
    <div className="container">
      <div className="three-col-grid">
        {features.map(({ image, heading, description }) => (
          <div key={heading}>
            <img {...image} />
            <h3>{heading}</h3>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
)
