import React from "react"

import "./cta.scss"

export default () => (
  <section id="cta" className="dark">
    <div className="container">
      <h1>Frost Diesel Injection</h1>
      <p>Quality Workmanship & Old Fashioned Service</p>
      <a href="tel:32744834" className="button">
        <b>Call Us Today!</b>
        <br />
        3274 4834
      </a>
    </div>
  </section>
)
