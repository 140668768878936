import React from "react"

import "./testimonials.scss"

export default () => (
  <section id="testimonials">
    <div className="container">
      <h2>Testimonials</h2>
      <blockquote>
        <p>
          "Diesel Australia have been using Frost Diesel Injection for all our
          Cummins fuel system repairs for nearly 20 years. We are very happy
          with the professional service that Mike provides, and his knowledge of
          all things Cummins is very extensive. I would not hesitate to
          recommend Frost Diesel Injection to anyone looking for repairs to
          their Cummins fuel system."
        </p>
        <span className="author">- Guy Hall</span>
        <span>Workshop Manager at Diesel Australia</span>
      </blockquote>
    </div>
  </section>
)
