import React from "react"

import "./services.scss"
import quality from "../images/quality-imported-parts-used.jpg"
import durable from "../images/durable-and-long-lasting-diesel-repair.jpg"
import precision from "../images/precision-fitting-injector-plungers.jpg"

export default () => (
  <section id="our-services">
    <div className="container">
      <h2>About Our Diesel Injection Services</h2>
      <p>
        Mike has 30 years of Cummins fuel injection experience.
        <br />
        VE pumps and all other injectors are serviced and fitted with genuine
        parts.
      </p>
      <div className="three-col-grid">
        <div>
          <img
            src={quality}
            alt="Quality imported parts from USA used with Cummins PT"
          />
          <h3>High Quality parts from the USA</h3>
          <p>
            Hards parts imported from the USA are used for Cummins PT fuel
            injection.
          </p>
        </div>
        <div>
          <img src={durable} alt="Durable and Long Lasting Diesel Repair" />
          <h3>Durable & Long Lasting</h3>
          <p>
            Injectors that have been reconditioned by Frost Diesel Injection
            have come back after 10 years use in running engines with leakages
            still within new limits.
          </p>
        </div>
        <div>
          <img src={precision} alt="Precision fitting injector plungers" />
          <h3>Precision Fitting</h3>
          <p>
            A Sunnen hone is used inhouse to hone oversized Cummins injector
            plungers to a precision fit.
          </p>
        </div>
      </div>
      <a href="tel:32744834" className="button">
        Questions about our services?
        <br />
        <b>Call 3274 4834</b>
      </a>
    </div>
  </section>
)
