import React from "react"

import "./contact.scss"

export default ({ children }) => (
  <section id="contact-us">
    <div className="container">
      <h2>Contact Us</h2>
      <div className="two-col-grid">
        <div>
          <h3>Send Us A Message</h3>
          {children}
        </div>
        <div>
          <h3>Our Contact Details</h3>
          <p>
            <b>Phone: </b>
            <a href="tel:32744834">(07) 3274 4834</a>
            <br />
            <b>Address: </b>
            <a href="https://www.google.com.au/maps/place/Frost+Diesel+Injection/@-27.5686915,153.0368817,14z/data=!4m2!3m1!1s0x6b914543e3bedf43:0x762a1e91fd1e8b58">
              802 Boundary Rd, Coopers Plains QLD 4108
            </a>
            <br />
          </p>
          <h3>Our Location</h3>
          <div className="google-map">
            <a
              className="button"
              href="https://www.google.com.au/maps/place/Frost+Diesel+Injection/@-27.5686915,153.0368817,14z/data=!4m2!3m1!1s0x6b914543e3bedf43:0x762a1e91fd1e8b58"
            >
              Find us on Google Maps
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
)
